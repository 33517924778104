/* stylelint-disable declaration-empty-line-before */

@import 'src/account-and-customer-profile/src/globalStyles/account.vars';

.c-offer-card-drawer {
    z-index: $z-index-sparky-override-999;

    .sparky-c-drawer__window {
        width: $full-width-percentage;

        @media all and (min-width: $tablet-min) {
            width: $full-width-mobile;
        }
    }

    .sparky-c-drawer__header,
    .sparky-c-drawer__body {
        padding: $padding-md;
    }

    .sparky-c-heading {
        color: $heading-gray;
    }

    .sparky-c-heading.sparky-c-heading--headline {
        font-size: var(--sparky-font-size-20) !important;
    }

    .sparky-c-media-block__body {
        margin-left: $margin-md;
        row-gap: $gap-md;
    }

    .sparky-c-drawer__close-button {
        @include close-button-colors;
        @include set-width-and-height(32px);

        // drawer close button.  Not accessible via Sparky props
        .sparky-c-icon--x {
            @include set-width-and-height(20px);
        }
    }

    .c-offer-card-details {
        img {
            @include set-width-and-height($offer-image-width-small);
            border-radius: 0.5rem;
        }

        .offer-card-details-description {
            margin: $margin-md $margin-zero;
        }

        .offer-card-button-full-width {
            height: 48px;
        }
    }

    .offer-card-drawer-terms {
        border-top: $section-border;
        margin-top: $margin-lg;

        @media all and (min-width: $tablet-min) {
            margin-top: $margin-xl;
        }

        #offer-card-drawer-terms-header {
            margin-top: $margin-lg;

            @media all and (min-width: $tablet-min) {
                margin-top: $margin-xl;
            }
        }

        .offer-drawer-terms-description {
            margin-top: $margin-sm;

            @media all and (min-width: $tablet-min) {
                margin-top: $margin-md;
            }
        }
    }

    .shop-offer-btn {
        font-size: 16px;
        height: 48px;
        margin-top: $margin-md;
    }
}
